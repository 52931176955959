import avatar4 from "assets/img/avatars/avatar4.png";

import { ImCross } from "react-icons/im";
import Card from "components/card";
import { TiTick } from "react-icons/ti";

const Alerts = () => {
  const HistoryData = [
    {
      patientProfilePicture: avatar4,
      patientName: "Mark Benjamin",
      type: "New Appointment",
      date: "32 minutes",
    },
    {
        patientProfilePicture: avatar4,
        patientName: "Michael Scofield",
        type: "Appointment Cancelled",
        date: "1.5 hours",
      },
      {
        patientProfilePicture: avatar4,
        patientName: "Gregory House",
        type: "New Review",
        date: "2 hours",
      },
      {
        patientProfilePicture: avatar4,
        patientName: "Michael Scofield",
        type: "Emergency",
        date: "05:00 PM",
      },
        ];

  return (
    <Card extra={"mt-3 !z-5 overflow-hidden"}>
      {/* HistoryCard Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          Alerts
        </div>
      </div>

      {/* History CardData */}

      {HistoryData.map((data, index) => (
        <div className="flex h-full w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          <div className="flex items-center gap-3">
            <div className="flex h-16 w-16 items-center justify-center">
              <img
                className="h-full w-full rounded-xl"
                src={data.patientProfilePicture}
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-base font-bold text-navy-700 dark:text-white">
                {" "}
                {data.type}
              </h5>
              <p className="mt-1 text-sm font-normal text-gray-600">
                {" "}
                {data.patientName}{" "}
              </p>
            </div>
          </div>

        <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
            <div className="ml-2 flex items-center text-sm font-normal text-navy-600 dark:text-white">
                <p>{data.date}</p>
            </div>
        </div>
        </div>
      ))}
    </Card>
  );
};

export default Alerts;
