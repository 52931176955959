import { useRef } from "react";
import { WebRTCAdaptor } from "@antmedia/webrtc_adaptor";

const useWebRTCAdaptor = (props) => {
  const {
    playOnly,
    infoCallback,
    errorCallback,
  } = props;
  const ANTMEDIA_URL = "localhost:5080";
  const ANTMEDIA_APP_NAME = "LiveApp";

  const urlParams = new URLSearchParams(window.location.search);
  const playOnlyVideo=urlParams.get("playOnly");

  const mediaConstraints = {
    video: !playOnlyVideo ? true : false,
    audio: !playOnlyVideo ? true : false,
    facingMode: { exact: "user" },
  };

  const webRTCAdaptorRef = useRef(null);

  if (!webRTCAdaptorRef.current) {
    webRTCAdaptorRef.current = new WebRTCAdaptor({
      websocket_url: `ws://${ANTMEDIA_URL}/${ANTMEDIA_APP_NAME}/websocket`,
      mediaConstraints: mediaConstraints,
      debug: true,
      isPlayMode: playOnly,
      localVideoId: "localVideo",
      callback: infoCallback,
      callbackError: errorCallback,
    });
  }

  return { webRTCAdaptor: webRTCAdaptorRef.current };
};

export default useWebRTCAdaptor;
