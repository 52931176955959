import { Box, CircularProgress } from "@mui/material"

export const Loader=()=>{
  return(
    <Box sx={{ display: 'flex',alignItems:"center",justifyContent:"center",height:"100%",width:"100%",position:"absolute",top:"0",left:"0",right:"0",bottom:"0" }}>
      <CircularProgress size="5rem" sx={{color:"white"}} thickness={2.3}/>

    </Box>
  )
}
