import { MdOutlineTimer } from "react-icons/md";

const WelcomeWidget = (props: {
  icon: JSX.Element;
  title: string;
  descFirst: string;
  descSecond: string;
  extra?: string;
}) => {
  const {icon, title, descFirst, descSecond, extra } = props;
  return (
    <div
      className={`flex h-fit w-full flex-col rounded-[20px] bg-white bg-clip-border p-4 !pb-10 shadow-3xl shadow-shadow-500 dark:!bg-navy-800 dark:shadow-none 2xl:flex-row 3xl:h-[310px] ${extra}`}
      style={{ justifyContent: "center", alignItems: "center" }}
    >
      <div className="w-full justify-between pt-6 3xl:ml-8">
        <div>
          <div className="flex flex-col 2xl:flex-row justify-center">
            <div>
              <h2 className="font-bold leading-7 text-navy-700 dark:text-white md:text-[18px] 3xl:text-[28px] 3xl:leading-10">
                {title}
              </h2>
              <p className="mt-3 text-base text-navy-600">{descFirst} </p>
              <p className="mt-3 text-base text-navy-600">{descSecond} </p>
            </div>
          </div>
        </div>
      </div>

      <div
        className={` mr-8 flex min-h-[200px] min-w-full items-center justify-center rounded-xl text-[100px] text-black 2xl:h-[270px] 2xl:min-w-[270px]`}
      >
        {icon}
      </div>
    </div>
  );
};

export default WelcomeWidget;
