import { MdMessage, MdPhone, MdVideoCall } from "react-icons/md";

const PatientInfoHeader = (props: {
  name: string;
  username: string;
  photo: string;
  mb: string;
}) => {
  const { name, username, photo, mb } = props;
  return (
    <div className={`z-10 flex items-center justify-between ${mb} `}>
      <div className="flex items-center gap-3">
        <div className="h-12 w-12 rounded-full">
          <img src={photo} className="h-full w-full rounded-full" alt="" />
        </div>
        <div className="">
          <p className="text-base font-bold text-navy-700 dark:text-white">
            {' '}
            {name}{' '}
          </p>
          <p className="text-sm font-medium text-gray-600"> {username} </p>
        </div>
      </div>
      <div className="flex gap-2 mr-4 text-xl">
        <button
          className={`flex h-12 w-12 items-center justify-center rounded-full bg-gray-100 !font-bold text-brand-500 `}
        >
          <MdVideoCall />
        </button>
        <button
          className={`flex h-12 w-12  items-center justify-center rounded-full bg-gray-100 !font-bold text-brand-500 `}
        >
          <MdPhone />
        </button>
        <button
          className={`flex h-12 w-12  items-center justify-center rounded-full bg-gray-100 !font-bold text-brand-500 `}
        >
          <MdMessage />
        </button>
      </div>
    </div>
  );
};

export default PatientInfoHeader;
