import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import "./App.css";
import webRTCAdaptors from "./webRTCAdaptor";
import { Icon } from "@iconify/react";
import { makeStyles } from "@mui/styles";
import CustomDialog from "./CustomDialog";
import { Loader } from "./Loader";
const useStyles = makeStyles({
  handRaise: {
    position: "absolute",
    top: "0px",
    textAlign: "center",
    fontSize: "25px",
    right: "0px",
  },
  otherHandRaise: {
    position: "absolute",
    top: "3px",
    textAlign: "center",
    fontSize: "36px",
    right: "58px",
  },
  bottomBar: {
    position: "absolute",
    width: "100vw",
    bottom: 0,
    margin: 0,
  },
  fullScreenSession: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 9999,
    marginTop: 0,
    position: "fixed",
    top: 0,
    right: 0,
    bottom: "100px",
    left: 0,
    height: "100%",
    padding: "10px",
    width: "100%",
    background: "#27374D",
  },
  width80: {
    width: "80%",
  },
  width72: {
    width: "72%",
  },
});

const generateRandomString = (n) => {
  let randomString = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < n; i++) {
    randomString += characters.charAt(
      Math.floor(Math.random() * characters.length)
    );
  }
  return randomString;
};

const getUrlParameter = (sParam, search) => {
  if (typeof search === undefined || search == null) {
    search = window.location.search;
  }
  let sPageURL = decodeURIComponent(search.substring(1)),
    sURLVariables = sPageURL.split("&"),
    sParameterName,
    i;
  for (i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
};

export const getInitials = (content) => {
  if (!content) {
    return "";
  }
  const nameArray = content.split(" ");
  const firstName = nameArray[0].charAt(0).toUpperCase();
  const lastName = nameArray[nameArray.length - 1].charAt(0).toUpperCase();

  return firstName + lastName;
};

var reconnecting = false;
var publishReconnected;
var playReconnected;

const VideoCall = (props) => {
  let roomId = props.roomId;
  const [roomName, setRoomName] = useState(roomId);
  const [initialized, setInitialized] = useState(false);
  const [isCameraOn, setisCameraOn] = useState(true);
  const [isMuted, setisMuted] = useState(true);
  const [fullscreen, setFullscreen] = useState(true);
  const [showHand, setShowHand] = useState(false);
  const [remoteVideoOn, setRemoteVideoOn] = useState(true);
  const [remoteAudioOn, setRemoteAudioOn] = useState(true);
  const [loading,setLoading]=useState(true);
  const [publishStreamId, setPublishStreamId] = useState(
    generateRandomString(12)
  );
  const [localStream, setLocalStream] = useState(null);
  const [isDataChannelOpen, setIsDataChannelOpen] = useState(false);
  const [showHandOnOtherSide, setShowHandOnOtherSide] = useState(false);
  const [open, setOpen] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const classes = useStyles();
  let token = undefined;
  let streamName = getUrlParameter("streamName");
  let subscriberId = undefined;
  let subscriberCode = undefined;
  let allParticipants = {};
  var videoTrackAssignmentsIntervalJob = null;

  const [isPublished, setIsPublished] = useState(false);
  const [isPlayed, setIsPlayed] = useState(false);
  const [isJoining, setIsJoining] = useState(false);

  const roomNameData = urlParams.get("name");
  const userData = urlParams.get("userdata");
  const sessionData = urlParams.get("convertedSessionData");
  const isTherapist = urlParams.get("isTherapist");

  const decodedSession = userData ? JSON.parse(atob(userData)) : null;

  const self = urlParams.get("self");
  const remote = urlParams.get("remote");
  const isRecording = urlParams.get("isRecording");

  const converteSessionData = sessionData
    ? JSON.parse(atob(sessionData))
    : null;

  let playOnly = urlParams.get("playOnly");

  if (playOnly == null) {
    playOnly = false;
  }

  const showOtherHandRaise = () => {
    setTimeout(() => {
      setShowHandOnOtherSide(true);
    }, 5000);
  };

  function infoCallback(info, obj) {
    if (info === "initialized") {
      setInitialized(true);
      setisCameraOn(true);
      setisMuted(true);
    } else if (info === "broadcastObject") {
      if (obj.broadcast === undefined) {
        return;
      }
      let broadcastObject = JSON.parse(obj.broadcast);
      console.log("broadcastObject => ", broadcastObject);
      if (obj.streamId === roomName) {
        handleMainTrackBroadcastObject(broadcastObject);
      } else {
        handleSubtrackBroadcastObject(broadcastObject);
      }
    } else if (info === "newTrackAvailable") {
      playVideo(obj);
    } else if (info === "publish_started") {
      console.debug("publish started to room " + roomName);
      setIsPublished(true);
      if (reconnecting) {
        // we need to set the local video again after the reconnection
        let newLocalVideo = document.getElementById((typeof publishStreamId === "undefined")? "localVideo" : publishStreamId);
        //localVideoCreate(newLocalVideo);
        // we need to set the setVideoCameraSource to be able to update sender source after the reconnection
        webRTCAdaptor?.mediaManager.setVideoCameraSource(publishStreamId, webRTCAdaptor?.mediaManager.mediaConstraints, null, true);
        webRTCAdaptor?.getBroadcastObject(roomName); // FIXME: maybe this is not needed, check it
        publishReconnected = true;
        reconnecting = !(publishReconnected && playReconnected);
        return;
      }
      webRTCAdaptor?.enableStats(publishStreamId);
    } else if (info === "publish_finished") {
      console.debug("publish finished");
      setIsPublished(false);
    } else if (info === "browser_screen_share_supported") {
    } else if (info === "session_restored") {
      console.log("**** session_restored:" + reconnecting);
      if (reconnecting) {
        publishReconnected = true;
        reconnecting = !(publishReconnected && playReconnected);
      }
    } else if (info === "play_started") {
      setIsPlayed(true);
      webRTCAdaptor?.getBroadcastObject(roomName);
      requestVideoTrackAssignmentsInterval();

      if (reconnecting) {
        playReconnected = true;
        reconnecting = !(publishReconnected && playReconnected);
      }
    } else if (info === "play_finished") {
      removeAllRemoteVideos();
      setIsPlayed(false);
      clearInterval(requestVideoTrackAssignmentsInterval);
      videoTrackAssignmentsIntervalJob = null;
    } else if (info === "data_channel_opened") {
      setIsDataChannelOpen(true);
    } else if (info === "data_channel_closed") {
      setIsDataChannelOpen(false);
    } else if (info === "data_received") {
      if (dataReceivedCallback) dataReceivedCallback(JSON.parse(obj.data));
      handleNotificationEvent(obj);
    } else if (info === "participant_left") {
      removeAllRemoteVideos();
    } else if (info === "updated_stats") {
      checkConnectionQuality(obj);
    } else if (info === "debugInfo") {
      handleDebugInfo(obj.debugInfo);
    } else if (info === "ice_connection_state_changed") {
      console.log("iceConnectionState Changed: ", JSON.stringify(obj))
      var iceState = obj.state;
      if (iceState === "failed" || iceState === "disconnected" || iceState === "closed") {

        setTimeout(() => {
          if (webRTCAdaptor?.iceConnectionState(publishStreamId) !== "checking" &&
            webRTCAdaptor?.iceConnectionState(publishStreamId) !== "connected" &&
            webRTCAdaptor?.iceConnectionState(publishStreamId) !== "completed") {
            reconnectionInProgress();
          }
        }, 5000);

      }
    }
  }

  function errorCallback(error, message) {
    let errorMessage;
    if (
      error.indexOf("NotAllowedError") !== -1 ||
      error.indexOf("PermissionDeniedError") !== -1
    ) {
      errorMessage = "You are not allowed to access camera and mic.";
    } else if (error.indexOf("ScreenSharePermissionDenied") !== -1) {
      errorMessage = "You are not allowed to access screen share";
    }

    if (error.indexOf("no_active_streams_in_room") != -1) {
    } else {
      console.error(message);
    }
  }

  function handleDebugInfo(debugInfo) {
    var infoText = "Client Debug Info\n";
    infoText += "Events:\n";
    infoText += "All Participants (" + Object.keys(allParticipants).length + "):\n";
    Object.entries(allParticipants).forEach(([key, value]) => {
      infoText += "- " + key + "\n";
    });
    //infoText += JSON.stringify(allParticipants) + "\n";
    infoText += "----------------------\n";
    infoText += debugInfo;

    console.error(infoText)
  }

  function reconnectionInProgress() {
    //reset UI releated states

    reconnecting = true;
    publishReconnected = false;
    playReconnected = false;

    console.warn("Connection lost. Trying reconnect...");
  }

  function requestVideoTrackAssignmentsInterval() {
    if (videoTrackAssignmentsIntervalJob === null) {
      videoTrackAssignmentsIntervalJob = setInterval(() => {
        webRTCAdaptor?.requestVideoTrackAssignments(roomName);
      }, 3000);
    }
  }

  function checkConnectionQuality(obj) {
    let rtt = ((parseFloat(obj.videoRoundTripTime) + parseFloat(obj.audioRoundTripTime)) / 2).toPrecision(3);
    let jitter = ((parseFloat(obj.videoJitter) + parseInt(obj.audioJitter)) / 2).toPrecision(3);
    let outgoingBitrate = parseInt(obj.currentOutgoingBitrate);

    let packageLost = parseInt(obj.videoPacketsLost) + parseInt(obj.audioPacketsLost);
    let packageSent = parseInt(obj.totalVideoPacketsSent) + parseInt(obj.totalAudioPacketsSent);
    let packageLostPercentage = 0;
    if (packageLost > 0) {
      packageLostPercentage = ((packageLost / parseInt(packageSent)) * 100).toPrecision(3);
    }

    if (rtt >= 150 || packageLostPercentage >= 2.5 || jitter >= 80 || ((outgoingBitrate / 100) * 80) >= obj.availableOutgoingBitrate) {
      console.warn("rtt:" + rtt + " packageLostPercentage:" + packageLostPercentage + " jitter:" + jitter + " Available Bandwidth kbps :", obj.availableOutgoingBitrate, "Outgoing Bandwidth kbps:", outgoingBitrate);
      console.warn("Connection quality is not good. Please check your network connection.");
    }
  }

  const dataReceivedCallback = useCallback((data) => {
    switch (data.event) {
      case "video-off":
        setRemoteVideoOn(false);
        break;
      case "video-on":
        setRemoteVideoOn(true);
        break;
    }
  }, []);

  const onHandClicked = async () => {
    sendNotificationEvent("HAND_RAISE");
    setShowHand(!showHand);
    setTimeout(() => {
      setShowHand(false);
    }, 5000);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMainTrackBroadcastObject = (broadcastObject) => {
    let participantIds = broadcastObject.subTrackStreamIds;
    let currentTracks = Object.keys(allParticipants);
    currentTracks.forEach((trackId) => {
      if (
        !allParticipants[trackId].isFake &&
        !participantIds.includes(trackId)
      ) {
        delete allParticipants[trackId];
      }
    });

    participantIds.forEach((pid) => {
      if (allParticipants[pid] === undefined) {
        webRTCAdaptor?.getBroadcastObject(pid);
      }
    });
  };

  const handleNotificationEvent = (data) => {
    let notificationEvent = JSON.parse(data.data);
    if (notificationEvent != null && typeof notificationEvent == "object") {
      let eventStreamId = notificationEvent.streamId;
      let eventTyp = notificationEvent.eventType;

      if (eventTyp != "UPDATE_SOUND_LEVEL") {
        console.log("Received data : ", data.data);
      }

      if (eventTyp == "CAM_TURNED_OFF") {
        setRemoteVideoOn(false);
        console.log("Camera turned off for : ", eventStreamId);
        webRTCAdaptor?.getBroadcastObject(roomName);
      } else if (eventTyp == "CAM_TURNED_ON") {
        setRemoteVideoOn(true);

        console.log("Camera turned on for : ", eventStreamId);
        webRTCAdaptor?.getBroadcastObject(roomName);
      } else if (eventTyp == "MIC_MUTED") {
        setRemoteAudioOn(false);
        console.log("Microphone muted for : ", eventStreamId);
        webRTCAdaptor?.getBroadcastObject(roomName);
      } else if (eventTyp == "MIC_UNMUTED") {
        setRemoteAudioOn(true);

        console.log("Microphone unmuted for : ", eventStreamId);
        webRTCAdaptor?.getBroadcastObject(roomName);
      } else if (eventTyp == "TRACK_LIST_UPDATED") {
        webRTCAdaptor?.getBroadcastObject(roomName);
      } else if (eventTyp == "HAND_RAISE") {
        showOtherHandRaise();
      }
    }
  };

  const removeAllRemoteVideos = () => {

    var children = document?.getElementById("players")?.children;
    let i = 1;
    if(children)
    {
      while (i < children.length) {
        document.getElementById("players").removeChild(children[i]);
        i++;
      }
    }

  };

  if (document.querySelector('input[name="videoSource"]')) {
    document.querySelectorAll('input[name="videoSource"]').forEach((elem) => {
      elem.addEventListener("change", function (event) {
        let item = event.target;
      });
    });
  }

  const getUserStatusMetadata = () => {
    let metadata = {
      isMuted: isMuted,
      isCameraOn: isCameraOn,
      playOnly: playOnly
    };
    return metadata;
  };

  useEffect(() => {
    if (webRTCAdaptor && initialized) {
      if (!playOnly) {
        publish(publishStreamId, token);
      }
      webRTCAdaptor?.play(
        roomName,
        token,
        roomName,
        [],
        subscriberId,
        subscriberCode
      );
    }
  }, [initialized]); // eslint-disable-line react-hooks/exhaustive-deps

  const leaveRoom = () => {
    window.parent.postMessage("24HoursTherapist-endSession", "*");

    allParticipants = {};
    webRTCAdaptor?.stop(publishStreamId);
    webRTCAdaptor?.stop(roomName);
    removeAllRemoteVideos();
  };

  const publish = (streamId, token) => {
    let metadata = getUserStatusMetadata();
    if (streamName == null || streamName == "undefined" || streamName == "") {
      streamName = streamId;
    }
    webRTCAdaptor?.publish(
      publishStreamId,
      token,
      subscriberId,
      subscriberCode,
      streamName,
      roomName,
      JSON.stringify(metadata)
    );
  };

  let incomingTrackId;
  const playVideo = (obj) => {
    console.log("Play video => ", obj);
    let roomId = roomName;
    incomingTrackId = obj.trackId.substring("ARDAMSx".length);

    if (incomingTrackId == roomId || incomingTrackId == publishStreamId) {
      return;
    }

    let video = document.getElementById("remoteVideo" + incomingTrackId);

    if (video == null) {
      createRemoteVideo(incomingTrackId);
      video = document.getElementById("remoteVideo" + incomingTrackId);
      video.srcObject = new MediaStream();

    }

    video.srcObject.addTrack(obj.track);

    obj.stream.onremovetrack = (event) => {
      console.log("track is removed with id: " + event.track.id);
      console.log(event);
      let removedTrackId = event.track.id.substring("ARDAMSx".length);
      removeRemoteVideo(removedTrackId);
    };
  };

  const createRemoteVideo = (streamId) => {
    let player = document.createElement("div");
    player.id = "player" + streamId;
    let video = document.createElement("video");
    video.id = "remoteVideo" + streamId;
    video.setAttribute("autoPlay", true);
    video.setAttribute("playsInline", true);
    video.style.width = "100%";
    video.style.height = isRecording ? "auto" : "100vh";
    video.style.objectFit = "cover";
    video.style.position = "relative";
    player.appendChild(video);
    let playersElement = document.getElementById("players");
    if (playersElement) {
      playersElement.appendChild(player);
      setLoading(false)
    }


  };
  console.log("loading**",loading)
  const removeRemoteVideo = (streamId) => {
    let video = document.getElementById("remoteVideo" + streamId);
    if (video != null) {
      let player = document.getElementById("player" + streamId);
      video.srcObject = null;
      document.getElementById("players").removeChild(player);
    }
  };

  const handleSubtrackBroadcastObject = (broadcastObject) => {
    if (
      broadcastObject.metaData !== undefined &&
      broadcastObject.metaData !== null
    ) {
      let userStatusMetadata = JSON.parse(broadcastObject.metaData);
      if (userStatusMetadata.isCameraOn !== undefined) {
        broadcastObject.isCameraOn = userStatusMetadata.isCameraOn;
      }
      if (userStatusMetadata.isMuted !== undefined) {
        broadcastObject.isMuted = userStatusMetadata.isMuted;
      }
    }
    allParticipants[broadcastObject.streamId] = broadcastObject;
  };

  const turnOffLocalCamera = () => {
    webRTCAdaptor?.turnOffLocalCamera();
    setisCameraOn(false);
    sendNotificationEvent("CAM_TURNED_OFF");
  };

  const turnOnLocalCamera = () => {
    webRTCAdaptor?.turnOnLocalCamera();
    setisCameraOn(true);
    sendNotificationEvent("CAM_TURNED_ON");
  };

  const muteLocalMic = () => {
    webRTCAdaptor?.muteLocalMic();
    setisMuted(false);
    sendNotificationEvent("MIC_MUTED");
    // updateUserStatusMetadata();
  };

  const unmuteLocalMic = () => {
    webRTCAdaptor?.unmuteLocalMic();
    setisMuted(true);
    sendNotificationEvent("MIC_UNMUTED");
    // updateUserStatusMetadata();
  };

  const updateUserStatusMetadata = () => {
    let metadata = getUserStatusMetadata();
    if (
      publishStreamId != null &&
      publishStreamId != "" &&
      publishStreamId != "undefined"
    ) {
      webRTCAdaptor?.updateStreamMetaData(
        publishStreamId,
        JSON.stringify(metadata)
      );
    }
  };

  const sendNotificationEvent = (eventType) => {
    if (isDataChannelOpen) {
      var notEvent = { streamId: publishStreamId, eventType: eventType };

      webRTCAdaptor?.sendData(publishStreamId, JSON.stringify(notEvent));
    }
  };

  const { webRTCAdaptor } = useCallback(
    webRTCAdaptors({
      playOnly,
      infoCallback,
      errorCallback,
    }),
    [
      playOnly,
      infoCallback,
      errorCallback,
    ]
  );



  useEffect(() => {
    let localVideo = document.getElementById("localVideo");
    let stream = null;

    const getLocalStream = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: !playOnly  ? true  : false,
          audio: false,
        });
        localVideo.srcObject = stream;
        setLocalStream(stream);
      } catch (error) {
        console.error("Error accessing media devices:", error);
      }
    };

    getLocalStream();

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const sendMessage = (data) => {
    webRTCAdaptor?.getDebugInfo(publishStreamId);
    if (data === "setting") {
      window.parent.postMessage("24HoursTherapist-setting", "*");
    } else if (data === "chatBox") {
      window.parent.postMessage("24HoursTherapist-chatBox", "*");
    } else if (data === "fullscreen") {
      window.parent.postMessage("24HoursTherapist-fullScreen", "*");
    } else if (data === "showHand") {
      window.parent.postMessage("24HoursTherapist-handRaise", "*");
    } else if (data === "noteEdit") {
      window.parent.postMessage("24HoursTherapist-noteEdit", "*");
    }
  };

  return isRecording ? (
    <>
      <div>
        <div
          id="players"
          style={{
            height: "30%",
            width: "30%",
          }}
        ></div>
      </div>
    </>
  ) : (
    <>
      <Grid
        sx={{
          backgroundColor: fullscreen ? "#27374D" : "white",
          height: "100vh",
        }}
      >
        <Grid container justifyContent={"center"} sx={{ height: "100%" }}>
          <Grid
            container
            xs={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              height: "100%",
            }}
          >
            <Box
              position={"relative"}
              sx={
                remoteVideoOn
                  ? {
                    borderRadius: {
                      md: "20px",
                      xs: "10px",
                    },
                    backgroundColor: "transparent",
                    boxShadow: 0,
                    width: "100%",
                    height: "100%",
                  }
                  : {
                    backgroundColor: "grey.400",
                    borderRadius: "20px",
                    boxShadow: 0,
                    width: "100%",
                    height: "100%",
                  }
              }
            >

              <div
                id="players"
                style={{
                  height: "100%",
                  width: "100%",
                  position: !remoteVideoOn ? "absolute" : "relative",
                }}
              >
                {" "}
              </div>

              {loading && <Loader/> }

              {remoteAudioOn ? null : (
                <div
                  style={{
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                    height: "40px",
                    width: "40px",
                    borderRadius: "50%",
                    background: "rgb(25,118,210)",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Icon
                    icon="ic:round-mic-off"
                    style={{ color: "white", fontSize: "17px" }}
                  />
                </div>
              )}

              {showHandOnOtherSide && (
                <div className={classes.otherHandRaise}>🖐️</div>
              )}

              {!remoteVideoOn && (
                <Box
                  sx={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    height: "100%",
                    background: "#27374D",
                    // borderRadius:"20px"
                  }}
                >
                  <Avatar
                    sx={{
                      backgroundColor: "grey.800",
                      height: { md: "10rem", xs: "5rem" },
                      width: { md: "10rem", xs: "5rem" },
                    }}
                  >
                    <Typography variant="h2" color={"white"}>
                      {getInitials(isTherapist ? self : remote)}
                    </Typography>
                  </Avatar>
                </Box>
              )}

              <Grid
                item
                xs={12}
                className="navbar"
                sx={{
                  position: "absolute",
                  bottom: "0",
                  top: "auto",
                  width: "100%",
                  borderRadius: "20px",
                }}
              >
                <Paper
                  sx={{
                    my: {
                      md: 4,
                      xs: 3,
                    },
                    bottom: "20px",
                    paddingRight: { sm: "25px" },
                    boxShadow: 0,
                    background: "#0000002e",
                    padding: 0,
                    height: "auto",
                    margin: 0,
                    marginBottom: "0 !important",
                  }}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                      padding: 0,
                      height: "auto",
                      paddingBottom: "0 !important",
                      margin: 0,
                      marginBottom: "0 !important",
                      "@media screen and (max-width: 767px)": {
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                        background: "#0000002e",
                      },
                    }}
                  >
                    <Box sx={{ flex: 1 }}>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "flex-end",
                        }}
                      >
                        {/* <Button
                          variant="text"
                          color="secondary"
                          id="24HoursTherapist-fullScreen"
                          onClick={() => sendMessage(fullscreen)}
                          sx={{
                            padding: {
                              md: "15px 5px",
                              xs: 0,
                            },
                            minWidth: "48px",
                            height: "48px",
                            fontSize: "20px",
                            "@media screen and (max-width: 767px)": {
                              minWidth: "40px",
                            },
                          }}
                        >
                          <Icon
                            icon="ant-design:expand-outlined"
                            color="white"
                          />
                        </Button> */}
                        <Button
                          onClick={() => sendMessage("setting")}
                          id="24HoursTherapist-setting"
                          variant="text"
                          color="secondary"
                          sx={{
                            padding: {
                              md: "15px 5px",
                              xs: 0,
                            },
                            minWidth: "48px",
                            height: "48px",
                            fontSize: "20px",
                            "@media screen and (max-width: 767px)": {
                              minWidth: "40px",
                            },
                          }}
                        >
                          <Icon icon="ep:setting" color="white" />
                        </Button>
                      </Stack>
                    </Box>
                    <Box>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          onClick={!isMuted ? unmuteLocalMic : muteLocalMic}
                          variant="contained"
                          sx={{
                            padding: {
                              md: "15px 5px",
                              xs: 0,
                            },
                            minWidth: "48px",
                            height: "48px",
                            fontSize: "20px",
                            margin: { xs: 0, md: "0 8px" },
                            "@media screen and (max-width: 767px)": {
                              minWidth: "40px",
                              width: "40px",
                              padding: "0 5px",
                            },
                          }}
                        >
                          <Icon
                            icon={
                              !isMuted
                                ? "ic:round-mic-off"
                                : "material-symbols:mic"
                            }
                          />
                        </Button>
                        <Button
                          onClick={
                            !isCameraOn ? turnOnLocalCamera : turnOffLocalCamera
                          }
                          variant="text"
                          color="secondary"
                          sx={{
                            padding: {
                              md: "15px 5px",
                              xs: 0,
                            },
                            minWidth: "48px",
                            height: "48px",
                            fontSize: "20px",
                            margin: { md: "0 8px", xs: 0 },
                            "@media screen and (max-width: 767px)": {
                              minWidth: "40px",
                            },
                          }}
                        >
                          <Icon
                            icon={
                              !isCameraOn
                                ? "humbleicons:camera-video-off"
                                : "bxs:video"
                            }
                            color="white"
                          />
                        </Button>
                        <Button
                          id="24HoursTherapist-endSession"
                          onClick={() => setOpen(true)}
                          variant="contained"
                          color="error"
                          sx={{
                            height: "48px",
                            margin: { md: "0 8px", xs: 0 },
                            fontSize: "20px",
                            "@media screen and (max-width: 767px)": {
                              minWidth: "40px",
                              width: "40px",
                              padding: "0 5px",
                            },
                          }}
                        >
                          <Icon icon="mdi:phone-hangup" />
                          <Box
                            sx={{
                              display: {
                                md: "block",
                                xs: "none",
                              },
                              marginLeft: {
                                md: "8px",
                                xs: 0,
                              },
                              fontSize: "16px",
                            }}
                          >
                            End Session
                          </Box>
                        </Button>
                      </Stack>
                    </Box>
                    <Box sx={{ flex: 1 }}>
                      <Stack
                        sx={{
                          flexDirection: "row",
                          justifyContent: "flex-start",
                        }}
                      >
                        {decodedSession?.user?.role === "therapist" && (
                          <Button
                            id="24HoursTherapist-noteEdit"
                            onClick={() => sendMessage("noteEdit")}
                            variant="text"
                            color="secondary"
                            sx={{
                              padding: {
                                md: "15px 5px",
                                xs: 0,
                              },
                              minWidth: "48px",
                              height: "48px",
                              fontSize: "20px",
                              "@media screen and (max-width: 767px)": {
                                minWidth: "40px",
                              },
                            }}
                          >
                            <Icon icon="mdi:note-edit" color="white" />
                          </Button>
                        )}

                        <Button
                          variant="text"
                          color="secondary"
                          sx={{
                            padding: {
                              md: "15px 5px",
                              xs: 0,
                            },
                            minWidth: "48px",
                            height: "48px",
                            fontSize: "20px",
                            "@media screen and (max-width: 767px)": {
                              minWidth: "40px",
                            },
                          }}
                          onClick={onHandClicked}
                        >
                          <Icon icon="mdi:hand-front-left" color="white" />
                        </Button>
                        <Button
                          id="24HoursTherapist-chatBox"
                          onClick={() => sendMessage("chatBox")}
                          variant="text"
                          color="secondary"
                          sx={{
                            padding: {
                              md: "15px 5px",
                              xs: 0,
                            },
                            minWidth: "48px",
                            margin: {
                              xs: 0,
                            },
                            height: "48px",
                            fontSize: "20px",
                            marginLeft: "8px",
                          }}
                        >
                          <Icon
                            icon="ic:baseline-chat-bubble-outline"
                            color="white"
                          />
                        </Button>
                      </Stack>
                    </Box>
                  </CardContent>
                </Paper>
              </Grid>

              <Box
                sx={{
                  position: "absolute",
                  bottom: "22px",
                  right: "30px",
                  width: "150px",
                  height: "150px",
                  borderRadius: "5px",
                }}
              >
                <CardMedia
                  component="video"
                  id="localVideo"
                  autoPlay={isCameraOn}
                  muted={!isMuted}
                  sx={{
                    borderRadius: "20px",
                    width: "100%",
                    objectFit: "cover",
                    display: isCameraOn ? "flex" : "none",
                  }}
                />

                {showHand && (
                  <div
                    id="24HoursTherapist-handRaise"
                    className={classes.handRaise}
                    onClick={() => sendMessage("showHand")}
                  >
                    🖐️
                  </div>
                )}

                {!isCameraOn && (
                  <Box
                    sx={{
                      position: "absolute",
                      display: "grid",
                      placeItems: "center",
                      bottom: "35px",
                      height: "7rem",
                      width: "9.5rem",
                      backgroundColor: "grey.800",
                      borderRadius: "20px",
                      right: "0",
                    }}
                  >
                    <Typography variant="h4" color={"white"}>
                      {getInitials(decodedSession?.user?.name)}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <CustomDialog
        open={open}
        onClose={handleClose}
        onEnd={() => {
          leaveRoom();
          window.location.href = "/physician/dashboard";
        }}
        fullscreen={fullscreen}
        title="End Session"
        desc="The session will end for everyone and all the activities will stop. You can’t undo this action."
        btnText="End Session"
        action="Don’t End"
        color="error"
      />
    </>
  );
};

export default VideoCall;
