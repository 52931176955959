import avatar4 from "assets/img/avatars/avatar4.png";

import Card from "components/card";
import { MdCheck, MdClose } from "react-icons/md";

const HistoryCard = () => {
  const HistoryData = [
    {
      patientProfilePicture: avatar4,
      patientName: "Mark Benjamin",
      type: "Consultation",
      isActive: true,
      date: "08:00 AM",
    },
    {
        patientProfilePicture: avatar4,
        patientName: "Michael Scofield",
        type: "First Visit",
        isActive: true,
        date: "09:00 AM",
      },
      {
        patientProfilePicture: avatar4,
        patientName: "Gregory House",
        type: "Consultation",
        isActive: false,
        date: "01:00 PM",
      },
      {
        patientProfilePicture: avatar4,
        patientName: "Michael Scofield",
        type: "Emergency",
        isActive: true,
        date: "05:00 PM",
      },
        ];

  function getAppointmentStatusIcon(isActive: boolean) {
    if (isActive) {
      return <div
            className={`flex h-8 w-8 items-center justify-center rounded-full border border-green-500 !font-bold text-green-500 `}
          >
            <MdCheck />
          </div>
    } else {
      return <div
            className={`flex h-8 w-8 items-center justify-center rounded-full border border-red-500 !font-bold text-red-500 `}
          >
            <MdClose />
          </div>
    }
  }

  return (
    <Card extra={"mt-3 !z-5 overflow-hidden"}>
      {/* HistoryCard Header */}
      <div className="flex items-center justify-between rounded-t-3xl p-3">
        <div className="text-lg font-bold text-navy-700 dark:text-white">
          Today's Appointments
        </div>
      </div>

      {/* History CardData */}

      {HistoryData.map((data, index) => (
        <div className="flex h-full w-full items-start justify-between bg-white px-3 py-[20px] hover:shadow-2xl dark:!bg-navy-800 dark:shadow-none dark:hover:!bg-navy-700">
          <div className="flex items-center gap-3">
            <div className="flex h-16 w-16 items-center justify-center">
              <img
                className="h-full w-full rounded-xl"
                src={data.patientProfilePicture}
                alt=""
              />
            </div>
            <div className="flex flex-col">
              <h5 className="text-base font-bold text-navy-700 dark:text-white">
                {" "}
                {data.patientName}
              </h5>
              <p className="mt-1 text-sm font-normal text-gray-600">
                {" "}
                {data.type}{" "}
              </p>
            </div>
          </div>

        <div className="mt-1 flex items-center justify-center text-navy-700 dark:text-white">
            <div>
                {getAppointmentStatusIcon(data.isActive)}
            </div>
            <div className="ml-2 flex items-center text-sm font-normal text-navy-600 dark:text-white">
                <p>{data.date}</p>
            </div>
        </div>
        </div>
      ))}
    </Card>
  );
};

export default HistoryCard;
