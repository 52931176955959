import React, { useContext, createContext, useState, ReactNode, FC } from "react";
import { useNavigate } from "react-router-dom";
import  secureLocalStorage  from  "react-secure-storage";

interface AuthContextType {
  token: string;
  user: any;
  loginAction: (data: any) => Promise<void>;
  logOut: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(JSON.parse((secureLocalStorage?.getItem("user")?.toString() || '{}')));
  const [token, setToken] = useState<string>(secureLocalStorage.getItem("token")?.toString() || "");
  const navigate = useNavigate();

  const loginAction = async (data: any) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/authenticate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
        body: JSON.stringify(data),
      });
      const res = await response.json();
      if (res.code === 200 && res.data) {
        let userData = res.data.user;
        userData["fullName"] = userData["firstName"] + " " + userData["lastName"];

        setUser(userData);
        setToken(res.data.token);
        secureLocalStorage.setItem("token", res.data.token);
        secureLocalStorage.setItem("user", JSON.stringify(userData));
        navigate("/physician/dashboard");
        return;
      }
      throw new Error(res.msg);

    } catch (err) {
      console.error(err);
    }
  };

  const logOut = () => {
    setUser(null);
    setToken("");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("user");
    navigate("/auth/");
  };

  return (
    <AuthContext.Provider value={{ token, user, loginAction, logOut }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
