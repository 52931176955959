import React from "react";
import {Button, Stack} from "@chakra-ui/react";
import InputField from "../../../../../components/fields/InputField";
import TextField from "components/fields/TextField"
import {IoMail} from "react-icons/io5";

const ContactUs = () => {
  return (
      <div
          className={`h-full w-full rounded-[20px] bg-white pb-8 font-dm dark:!bg-navy-800`}
      >

        <Stack marginStart={32} marginEnd={32} spacing={21}>
          <InputField
              id="name"
              label="Name"
              placeholder="Name"
          />
          <InputField
              id="mail"
              label="Mail"
              placeholder="Mail"
          />
          <TextField
              id="message"
              label="Message"
              placeholder="Message"
              cols={30}
              rows={5}
           />
          <Button leftIcon={<IoMail />} variant="outline" onClick={() => {
            if (document.getElementById("name").getAttribute('value') === "" || document.getElementById("mail").getAttribute('value') === "" || document.getElementById("message").getAttribute('value') === "") {
              alert("Please fill all the fields");
              return;
            }
            
            document.getElementById("name").setAttribute('value', "");
            document.getElementById("mail").setAttribute('value', "");
            document.getElementById("message").setAttribute('value', "");
            alert("Message sent successfully");
          }}className="linear flex items-center justify-center rounded-[20px] border px-5 py-1.5 text-base font-medium text-navy-700 transition duration-200 hover:cursor-pointer hover:bg-lightPrimary active:bg-gray-200 dark:!border-none dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
            Send Message
          </Button>
        </Stack>

      </div>
  );
};

export default ContactUs;
