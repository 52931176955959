import avatar1 from "assets/img/avatars/avatar1.png";

import tableDataTopCreators from "./variables/tableDataTopCreators";
import DocumentsCard from "./components/DocumentsCard";
import MedicalRecordsTable from "./components/MedicalRecordsTable";
import PatientInfoHeader from "./components/PatientInfoHeader";
import Card from "components/card";

const PatientDetails = () => {
  return (
    <div className="mt-3 grid h-full grid-cols-1 gap-5 xl:grid-cols-2 2xl:grid-cols-3">
      <div className="col-span-1 h-fit w-full xl:col-span-1 2xl:col-span-2">
      <Card extra={"mt-3 !z-5 overflow-hidden"}>
        <div className="mt-9" style={{ marginLeft: '12px' }}>
          <PatientInfoHeader
            mb=" mb-[26px] "
            name="Esther Berry"
            username="Pneumonia"
            photo={avatar1}
          />
        </div>

        <div className={`flex items-center justify-between mb-[42px]`} style={{ marginLeft: '12px' }}>
          <div className="hover:cursor-pointer">
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Date of Birth
            </p>
            <p className="text-sm text-gray-600"> January, 14 2022 </p>
          </div>
          <div className="hover:cursor-pointer">
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Address
            </p>
            <p className="text-sm text-gray-600"> Backpain </p>
          </div>
          <div className="hover:cursor-pointer">
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Marital State
            </p>
            <p className="text-sm text-gray-600"> Married </p>
          </div>
          <div className="hover:cursor-pointer"  style={{ marginRight: '16px' }}>
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Insurance
            </p>
            <p className="text-sm text-gray-600"> Delta Tech </p>
          </div>
        </div>

        <div className={`flex items-center justify-between mb-[42px]`} style={{ marginLeft: '12px' }}>
          <div className="hover:cursor-pointer">
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Social Number
            </p>
            <p className="text-sm text-gray-600"> 034824 </p>
          </div>
          <div className="hover:cursor-pointer">
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Phone Number
            </p>
            <p className="text-sm text-gray-600"> +(270) 545-0154 </p>
          </div>
          <div className="hover:cursor-pointer">
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Gender
            </p>
            <p className="text-sm text-gray-600"> Female </p>
          </div>
          <div className="hover:cursor-pointer"  style={{ marginRight: '16px' }}>
            <p className="text-base font-bold text-navy-700 dark:text-white">
              Registered On
            </p>
            <p className="text-sm text-gray-600"> January, 14 2022 </p>
          </div>
        </div>
      </Card>
    </div>

      {/* right side section */}

      <div className="col-span-1 h-full w-full rounded-xl 2xl:col-span-1">
        <DocumentsCard />
        <div className="mb-3" />
        <MedicalRecordsTable tableData={tableDataTopCreators} />
      </div>
    </div>
  );
};

export default PatientDetails;
