import Profile from "./components/Profile";
import WelcomeWidget from "components/card/WelcomeWidget";
import AppointmentAnalytics from "./components/AppointmentAnalytics";
import TodayAppointments from "./components/TodayAppointments";

import { FaStethoscope } from "react-icons/fa";
import { MdPerson } from "react-icons/md";
import MiniStatistics from "./components/MiniStatistics";
import { useAuth } from "context/AuthProvider";

const Dashboard = () => {
  const auth = useAuth();
  
  return (
    <div className="mt-3 flex h-full w-full flex-col gap-[20px] rounded-[20px] xl:flex-row">
      <div className="h-full w-full rounded-[20px]">
        {/* left side */}
        <div className="col-span-9 h-full w-full rounded-t-2xl xl:col-span-6">
        <WelcomeWidget
          extra="mb-5"
          icon={<FaStethoscope />}
          title={`Welcome ${auth.user.firstName}!`}
          descFirst="You have 7 patients remaining today!"
          descSecond="Don't forget to check their files before the meeting."
        />
          {/* overall & Balance */}
          <div className="mb-5 grid grid-cols-6 gap-5">
            <div className="col-span-6 h-full w-full rounded-xl 3xl:col-span-4">
              <TodayAppointments />
            </div>
            <div className="col-span-6 w-full 3xl:col-span-2">
              <AppointmentAnalytics />
              <MiniStatistics
                icon={<MdPerson className="text-4xl" />}
                title="New Patients"
                value="3"
                bgColor={'bg-lightPrimary dark:!bg-navy-700'}
                growth={'+5%'}
                growthColor="text-green-500"
                cardBg="bg-white"
                titleColor="text-gray-600"
                valueColor="text-navy-700 dark:text-white"
                detailColor="text-gray-600"
                iconColor="text-brand-500 dark:text-white"
              />
            </div>
          </div>
        </div>
      </div>

      {/* line */}
      <div className="flex w-0 bg-gray-200 dark:bg-navy-700 xl:w-px" />

      {/* right section */}
      <div className="h-full w-full xl:w-[400px] xl:min-w-[300px] 2xl:min-w-[400px]">
        <Profile />
      </div>
    </div>
  );
};

export default Dashboard;
